import { IM, Language, useAlert, useEvent, useModalController } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import Color from 'color'
import dayjs from 'dayjs'
import { i18n as i18next } from 'i18next'
import React, { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DATE_FORMAT, DnDSource, EventItem, Resource, SchedulerData, ViewType } from 'react-big-scheduler-stch'
import { ColorSchemeName, StyleSheet } from 'react-native'
import { toast } from 'react-toastify'
import { useRecoilValue } from 'recoil'
import { serializeError } from 'serialize-error'

import { api } from '../../apis/apiCalls'
import {
  Activity,
  ActivityEmployee,
  ActivityState,
  ConnectedActivity,
  Qualification,
  Technician,
  Unavailable,
  UnavailableType,
} from '../../apis/types/apiResponseTypes'
import DndActivityListCard from '../../cards/activityList/DndActivityListCard'
import DndAbsenceCard from '../../cards/DndAbsenceCard'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useLanguageSelect from '../../hooks/useLanguageSelect'
import useUserSettings from '../../hooks/useUserSettings'
import ActivityCreationModal from '../../modals/activity/ActivityCreationModal'
import { DnDType, GanttChartRef, InactiveSlots, ThemeColorExpanded } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import appUtils from '../../utils/appUtils'
import { chartUtils } from '../../utils/chartUtils'
import { imDateUtils } from '../../utils/infominds/imDateUtils'
import {
  ganttChartClosedPlannerSideViewAtom,
  ganttChartDateLabelAtom,
  ganttChartSelectedTechniciansAtom,
  ganttChartViewAtom,
  plannerActivityExpirationCheckAtom,
  plannerShowClosedActivitiesAtom,
} from '../../utils/stateManager'
import { ActivityCreateOrEditViewProps } from '../../views/activities/ActivityCreateOrEditView'
import PlannerSideView from '../../views/planner/PlannerSideView'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import useRequest from '../Infominds/hooks/useRequest'
import GanttChart from './GanttChart'
import useGanttChartWrapperGetApi from './GanttChartWrapper.get.api'
import useGanttChartWrapperRequestApi from './GanttChartWrapper.request.api'
import withDnDContext from './withDnDContext'

// #region DnD functions
const taskDndSource = new DnDSource(
  props => {
    // @ts-ignore boh
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return props.activity
  },
  // @ts-ignore ok like this
  DndActivityListCard,
  true,
  DnDType.ACTIVITY
)

const absenceDndSource = new DnDSource(
  props => {
    // @ts-ignore boh
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return props.absence
  },
  // @ts-ignore ok like this
  DndAbsenceCard,
  true,
  DnDType.ABSENCE
)
// #endregion

type PutResponse = Pick<ActivityEmployee, 'srvActivityEmployeeId' | 'srvActivityId' | 'srvActivityTypeId' | 'srvActivityYear'>

export interface GanttChartWrapperProps {
  resources: Resource[]
  qualifications: Qualification[]
  technicians: Technician[]
  theme: ThemeColorExpanded
  colorScheme: NonNullable<ColorSchemeName>
  language: Language
  i18n: i18next
  inactiveSections?: InactiveSlots[]
  companyId: number | undefined
  objectId: number | undefined
}

const ITEM_HEIGHT = 54
const HEADER_HEIGHT = 40

const GanttChartWrapper = ({
  technicians,
  theme,
  colorScheme,
  resources,
  i18n,
  language,
  qualifications,
  inactiveSections,
  companyId,
  objectId,
}: GanttChartWrapperProps) => {
  const { alert } = useAlert()
  const { languageSelect } = useLanguageSelect()
  const { userSettings, taskType } = useUserSettings()
  const createActivityModal = useModalController<ActivityCreateOrEditViewProps>()
  const navigation = useNavigation()
  const { sessionKey } = useAuthentication()

  // #region Api
  const { data, loadData } = useGanttChartWrapperGetApi()
  const { removeUnavailable, editUnavailable } = useGanttChartWrapperRequestApi()
  const { request: createUnavailable } = useRequest(api.activities.unavailables.post, {
    onSuccess: res => {
      if (postUnavailable.current && res.srvUnavailableId && dateLabel) {
        const eventItem = chartUtils.createUnavailableEventItem(
          {
            ...postUnavailable.current,
            srvUnavailableId: res.srvUnavailableId,
            unavailableTypeNote:
              languageSelect({
                de: postUnavailable.current.noteDe,
                it: postUnavailable.current.noteIt,
                en: postUnavailable.current.noteEn,
              }) ?? '',
            unavailableTypePlanColor: postUnavailable.current.planColor,
            unavailableTypeTitle:
              languageSelect({
                de: postUnavailable.current.titleDe,
                it: postUnavailable.current.titleIt,
                en: postUnavailable.current.titleEn,
              }) ?? '',
          },
          selectedView,
          dateLabel.date
        )
        eventItem && chartRef.current?.add({ ...eventItem })
        postUnavailable.current = undefined
      }

      if (duplicateUnavailable.current && res.srvUnavailableId) {
        chartRef.current?.add({
          ...duplicateUnavailable.current,
          srvUnavailableId: res.srvUnavailableId,
          id: chartUtils.createUnavailableId({
            srvUnavailableId: res.srvUnavailableId,
            srvUnavailableTypeId: duplicateUnavailable.current.srvUnavailableTypeId as number,
          }),
        })
        duplicateUnavailable.current = undefined
      }
    },
    onError: err => {
      if (serializeError(err).Code === 406) {
        alert(i18n.t('ERROR'), i18n.t('ERROR_UNAVAILABLE'), [
          {
            text: i18n.t('OK'),
            onPress: () => null,
          },
        ])
      } else {
        alert(i18n.t('ERROR'), i18n.t('ERROR_CREATE_EVENT'), [
          {
            text: i18n.t('OK'),
            onPress: () => null,
          },
        ])
      }
    },
  })
  const { loading: loadingNewActivity, loadItem: loadActivity } = useControlledLoader(api.activities.getList, {
    onLoadedCallback: act => {
      const activity = act[0]
      createActivityModal.show({
        presetActivity: activity,
      })
    },
  })
  // #endregion

  // #region State functions
  const [chartReady, setChartReady] = useState(false)
  const dateLabel = useRecoilValue(ganttChartDateLabelAtom(sessionKey))
  const selectedView = useRecoilValue(ganttChartViewAtom(sessionKey))
  const savedCheckedEmployee = useRecoilValue(ganttChartSelectedTechniciansAtom(sessionKey))
  const plannerSideClosed = useRecoilValue(ganttChartClosedPlannerSideViewAtom(sessionKey))
  const showClosedActivities = useRecoilValue(plannerShowClosedActivitiesAtom(sessionKey))
  const expirationCheck = useRecoilValue(plannerActivityExpirationCheckAtom(sessionKey))
  // #endregion

  // #region Ref
  const parentRef = useRef(null)
  const chartRef = createRef<GanttChartRef>()
  const schedulerData = useRef<SchedulerData>()
  const postUnavailable = useRef<UnavailableType & Pick<Unavailable, 'toDate' | 'fromDate' | 'technicianId'>>()
  const duplicateUnavailable = useRef<EventItem>()
  // #endregion

  // #region useEvent functions
  useEvent({ key: EVENT_KEYS.ACTIVITY_ASSIGNED }, () => refreshToPlanList())
  useEvent({ key: EVENT_KEYS.GANTT_CHART_NEXT }, () => chartRef.current?.next())
  useEvent({ key: EVENT_KEYS.GANTT_CHART_PREVIOUS }, () => chartRef.current?.previous())
  useEvent<Date>({ key: EVENT_KEYS.GANTT_CHART_DATE_PICK }, date => chartRef.current?.pick(date))
  const { emit: eventUpdateStart } = useEvent<{ event: EventItem[]; start: string }>(
    { key: EVENT_KEYS.GANTT_CHART_UPDATE_START_EVENT },
    ({ event, start }) => chartRef.current?.updateStart(event, start)
  )
  const { emit: eventUpdateEnd } = useEvent<{ event: EventItem[]; end: string }>({ key: EVENT_KEYS.GANTT_CHART_UPDATE_END_EVENT }, ({ event, end }) =>
    chartRef.current?.updateEnd(event, end)
  )
  const { emit: blur } = useEvent({ key: EVENT_KEYS.GANTT_CHART_BLUR })
  const { emit: refreshActivityView } = useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED })
  const { emit: refreshToPlanList } = useEvent({ key: EVENT_KEYS.GANTT_CHART_TO_PLAN_REFRESH })
  const { emit: eventRefreshGraph } = useEvent({ key: EVENT_KEYS.GANTT_CHART_REFRESH }, () => dateLabel && refreshGraph(dateLabel.date))
  // #endregion

  useFocusEffect(
    useCallback(() => {
      return blur
    }, [])
  )

  useEffect(() => {
    document.body.style.setProperty('--gantt-background-color', theme.gantt.background)
    document.body.style.setProperty('--text-color', theme.text)
    document.body.style.setProperty('--gantt-table-border', theme.gantt.border.primary)
    document.body.style.setProperty('--gantt-table-border-2', theme.gantt.border.secondary)
  }, [theme])

  useEffect(() => {
    const dayWidth = 84.0 / 7

    const previousView = schedulerData.current?.viewType
    schedulerData.current = new SchedulerData(
      dayjs().format(DATE_FORMAT),
      previousView ?? ViewType.Week,
      false,
      false,
      {
        dayMaxEvents: 99,
        weekMaxEvents: 9669,
        monthMaxEvents: 9669,
        quarterMaxEvents: 6599,
        yearMaxEvents: 9956,
        customMaxEvents: 9965,
        responsiveByParent: true,
        headerEnabled: false,
        resourceName: i18n.t('TECHNICIANS'),
        besidesWidth: 0,
        nonWorkingTimeBodyBgColor: theme.gantt.nonWorking.background,
        nonWorkingTimeHeadBgColor: theme.gantt.nonWorking.background,
        nonWorkingTimeHeadColor: theme.gantt.nonWorking.text,
        minuteStep: 30,
        dayResourceTableWidth: 200,
        weekResourceTableWidth: 200,
        monthResourceTableWidth: 200,
        dayCellWidth: 40,
        weekCellWidth: `${dayWidth}%`,
        monthCellWidth: 120,
        eventItemHeight: ITEM_HEIGHT,
        eventItemLineHeight: ITEM_HEIGHT,
        schedulerMaxHeight: 0,
        tableHeaderHeight: HEADER_HEIGHT,
        nonAgendaDayCellHeaderFormat: 'HH:mm',
        nonAgendaOtherCellHeaderFormat: `ddd DD${language === 'de' ? '.' : '/'}MM`,
        selectedAreaColor: Color(theme.gantt.selection).alpha(0.3).toString(),
        eventItemPopoverPlacement: 'bottomLeftMousePosition',
        eventItemPopoverColor: colorScheme === 'dark' ? theme.card.background : 'white',
        eventItemPopoverTrigger: 'click',
        resizeDelay: 200,
      },
      { isNonWorkingTimeFunc: (date, time) => chartUtils.isNonWorkingTimeFunc(date, time, inactiveSections) }
    )

    schedulerData.current.setSchedulerLocale(appUtils.getDayjsLocale(language))
    schedulerData.current.setResources(resources)

    setChartReady(true)
  }, [language])

  useEffect(() => {
    chartReady && dateLabel && chartRef.current?.pick(new Date(dateLabel.date))
  }, [chartReady])

  useEffect(() => {
    if (savedCheckedEmployee === undefined || !chartReady) return

    chartRef.current?.updateResources(chartUtils.prepareResources(resources, savedCheckedEmployee))
  }, [savedCheckedEmployee, chartReady])

  const notifyWrongTimeSlot = () => toast.warn(i18n.t('WARN_TIME_SLOT'))

  const refreshGraph = (date: Date) => {
    const start = imDateUtils.toUTC(date, { start: selectedView })
    const end = imDateUtils.toUTC(date, { end: selectedView })

    if (start === undefined || end === undefined) {
      console.error('Start or end date is undefined')
    }

    start && end && loadData(start, end)
  }

  const onSlotSelect = (slotId: string, _slotName: string, start: string, end: string, _type: string) => {
    const { id, dtoIds: technicianIds } = chartUtils.extractTechnicianType(slotId)
    const found = technicians.find(el => el.id === id)

    if (found && technicianIds.technicianId && technicianIds.technicianType) {
      createActivityModal.show({
        ...technicianIds,
        planDateFrom: start,
        planDateTo: end,
        technician: {
          id: technicianIds.technicianId,
          firstName: found.firstName,
          lastName: found.lastName,
          technicianType: technicianIds.technicianType,
        },
        presetActivity: {
          companyId,
          objectId,
        },
      })
    }
  }

  const onDragEnd = (event: EventItem, slotId: string, slotName: string, start: string, end: string, type?: string) => {
    const { id, type: technicianType, dtoIds: technicianIds } = chartUtils.extractTechnicianType(slotId)

    if (type === DnDType.ACTIVITY) {
      const { patch, post, showPopUp, showWrongTimeSlot } = chartUtils.prepareDroppedActivity(
        event as unknown as Activity,
        id,
        technicianType,
        technicians,
        start,
        end,
        selectedView,
        userSettings?.employee?.id
      )

      const startRequest = () => {
        const promises: Promise<Partial<PutResponse>>[] = []

        for (const newPatch of patch) {
          promises.push(api.activities.employees.put(newPatch) as unknown as Promise<PutResponse>)
        }

        for (const newPost of post) {
          promises.push(api.activities.employees.post(newPost))
        }

        showWrongTimeSlot && notifyWrongTimeSlot()
        Promise.allSettled(promises)
          .catch(() => {
            alert(i18n.t('ERROR'), i18n.t('ERROR_CREATE_EVENT'), [
              {
                text: i18n.t('OK'),
              },
            ])
          })
          .finally(() => {
            eventRefreshGraph()
            refreshActivityView()
            refreshToPlanList()
          })
      }

      if (showPopUp) {
        alert(i18n.t('WARNING'), i18n.t('DND_CREATION_WARNING'), [
          { text: i18n.t('CANCEL'), style: 'destructive' },
          { text: i18n.t('YES'), onPress: startRequest },
        ])
      } else {
        startRequest()
      }
    } else if (type === DnDType.ABSENCE) {
      const unavailableDragged = event as unknown as UnavailableType
      const body: UnavailableType & Pick<Unavailable, 'toDate' | 'fromDate' | 'technicianId' | 'technicianType'> = {
        ...unavailableDragged,
        ...technicianIds,
        fromDate: imDateUtils.toUTC(start),
        toDate: imDateUtils.toUTC(end),
      }
      postUnavailable.current = body

      createUnavailable(body)
    } else {
      const eventType = event.type as DnDType

      if (eventType === DnDType.ACTIVITY) {
        const activityDragged = event as unknown as Activity

        const edit = (editType: 'current' | 'all') => {
          const promises: Promise<string>[] = [
            api.activities.employees.put({
              ...activityDragged,
              ...technicianIds,
              planDateFrom: imDateUtils.toUTC(start),
              planDateTo: imDateUtils.toUTC(end),
              plannedByEmployeeId: userSettings?.employee?.id,
            }),
          ]

          if (editType === 'all') {
            activityDragged.connectedActivity?.forEach(el => {
              promises.push(
                api.activities.employees.put({
                  srvActivityEmployeeId: el.srvActivityEmployeeId,
                  srvActivityId: el.srvActivityId,
                  srvActivityTypeId: el.srvActivityTypeId,
                  srvActivityYear: el.srvActivityYear,
                  technicianId: el.technician.id,
                  technicianType: el.technician.technicianType,
                  planDateFrom: imDateUtils.toUTC(start),
                  planDateTo: imDateUtils.toUTC(end),
                  plannedByEmployeeId: userSettings?.employee?.id,
                })
              )
            })
          }

          Promise.allSettled(promises)
            .catch(() => {
              alert(i18n.t('ERROR'), i18n.t('ERROR_CREATE_EVENT'), [
                {
                  text: i18n.t('OK'),
                },
              ])
            })
            .finally(() => {
              eventRefreshGraph()
              refreshActivityView()
            })

          chartRef.current?.move(event, slotId, slotName, start, end)
        }

        if (activityDragged.connectedActivity?.length !== 0) {
          alert(i18n.t('WARNING'), i18n.t('DND_EDIT_CONNECTED_WARNING'), [
            { text: i18n.t('CANCEL'), style: 'destructive' },
            { text: i18n.t('EDIT_SELECTED'), onPress: () => edit('current') },
            { text: i18n.t('EDIT_ALL'), onPress: () => edit('all') },
          ])
        } else {
          edit('current')
        }
      } else {
        const unavailableDragged = event as unknown as Unavailable
        editUnavailable({
          ...unavailableDragged,
          ...technicianIds,
          fromDate: imDateUtils.toUTC(start),
          toDate: imDateUtils.toUTC(end),
        })

        chartRef.current?.move(event, slotId, slotName, start, end)
      }
    }
  }

  const onUpdateEnd = (event: EventItem, end: string) => {
    const ids = chartUtils.createEventIds(event)
    const { dtoIds: technicianIds } = chartUtils.extractTechnicianType(event.resourceId)

    const planStart = imDateUtils.toUTC(event.start)
    const planEnd = imDateUtils.toUTC(end)
    const type = event.type as DnDType

    if (type === DnDType.ACTIVITY) {
      const editedEvent: Partial<ActivityEmployee> = {
        ...ids,
        ...technicianIds,
        planDateFrom: planStart,
        planDateTo: planEnd,
        plannedByEmployeeId: userSettings?.employee?.id,
      }

      const edit = (editType: 'current' | 'all') => {
        eventUpdateEnd({ event: [event], end })
        const promises: Promise<string>[] = []

        promises.push(api.activities.employees.put(editedEvent))

        if (editType === 'all') {
          if (event.connectedActivity && Array.isArray(event.connectedActivity) && event.connectedActivity.length > 0) {
            event.connectedActivity.forEach((el: ConnectedActivity) =>
              promises.push(
                api.activities.employees.put({
                  ...editedEvent,
                  srvActivityEmployeeId: el.srvActivityEmployeeId,
                  srvActivityTypeId: el.srvActivityTypeId,
                  srvActivityYear: el.srvActivityYear,
                  srvActivityId: el.srvActivityId,
                  technicianId: el.technician.id,
                  technicianType: el.technician.technicianType,
                })
              )
            )
          }
        }

        Promise.allSettled(promises).catch(console.error).finally(eventRefreshGraph)
      }

      if (event.connectedActivity && Array.isArray(event.connectedActivity) && event.connectedActivity.length > 0) {
        alert(i18n.t('WARNING'), i18n.t('DND_EDIT_CONNECTED_WARNING'), [
          { text: i18n.t('CANCEL'), style: 'destructive' },
          { text: i18n.t('EDIT_SELECTED'), onPress: () => edit('current') },
          { text: i18n.t('EDIT_ALL'), onPress: () => edit('all') },
        ])
      } else {
        edit('current')
      }
    } else {
      eventUpdateEnd({ event: [event], end })
      editUnavailable({
        ...ids,
        ...technicianIds,
        fromDate: planStart,
        toDate: planEnd,
      })
    }
  }

  const onUpdateStart = (event: EventItem, start: string) => {
    const ids = chartUtils.createEventIds(event)
    const { dtoIds: technicianIds } = chartUtils.extractTechnicianType(event.resourceId)

    const planStart = imDateUtils.toUTC(start)
    const planEnd = imDateUtils.toUTC(event.end)
    const type = event.type as DnDType

    if (type === DnDType.ACTIVITY) {
      const editedEvent: Partial<ActivityEmployee> = {
        ...ids,
        ...technicianIds,
        planDateFrom: planStart,
        planDateTo: planEnd,
        plannedByEmployeeId: userSettings?.employee?.id,
      }

      const edit = (editType: 'current' | 'all') => {
        eventUpdateStart({ event: [event], start })
        const promises: Promise<string>[] = []

        promises.push(api.activities.employees.put(editedEvent))

        if (editType === 'all') {
          if (event.connectedActivity && Array.isArray(event.connectedActivity) && event.connectedActivity.length > 0) {
            event.connectedActivity.forEach((el: ConnectedActivity) =>
              promises.push(
                api.activities.employees.put({
                  ...editedEvent,
                  srvActivityEmployeeId: el.srvActivityEmployeeId,
                  srvActivityTypeId: el.srvActivityTypeId,
                  srvActivityYear: el.srvActivityYear,
                  srvActivityId: el.srvActivityId,
                  technicianId: el.technician.id,
                  technicianType: el.technician.technicianType,
                })
              )
            )
          }
        }

        Promise.allSettled(promises).catch(console.error).finally(eventRefreshGraph)
      }

      if (event.connectedActivity && Array.isArray(event.connectedActivity) && event.connectedActivity.length > 0) {
        alert(i18n.t('WARNING'), i18n.t('DND_EDIT_CONNECTED_WARNING'), [
          { text: i18n.t('CANCEL'), style: 'destructive' },
          { text: i18n.t('EDIT_SELECTED'), onPress: () => edit('current') },
          { text: i18n.t('EDIT_ALL'), onPress: () => edit('all') },
        ])
      } else {
        edit('current')
      }
    } else {
      eventUpdateStart({ event: [event], start })
      editUnavailable({
        ...ids,
        ...technicianIds,
        fromDate: planStart,
        toDate: planEnd,
      })
    }
  }

  const onRemove = (event: EventItem) => {
    const ids = chartUtils.createEventIds(event)

    const type = event.type as DnDType

    if (type === DnDType.ACTIVITY) {
      const remove = (removeType: 'current' | 'all') => {
        const promises: Promise<boolean>[] = []

        promises.push(api.activities.employees.delete(ids as Partial<ActivityEmployee>))

        if (removeType === 'all') {
          const activitiesConnected = event.connectedActivity as ConnectedActivity[]
          activitiesConnected.forEach(el =>
            promises.push(
              api.activities.employees.delete({
                srvActivityId: el.srvActivityId,
                srvActivityTypeId: el.srvActivityTypeId,
                srvActivityYear: el.srvActivityYear,
                srvActivityEmployeeId: el.srvActivityEmployeeId,
              } as Partial<ActivityEmployee>)
            )
          )
        }

        Promise.allSettled(promises)
          .catch(console.error)
          .finally(() => {
            eventRefreshGraph()
            refreshToPlanList()
          })
      }

      if (event.connectedActivity && Array.isArray(event.connectedActivity) && event.connectedActivity.length > 0) {
        alert(i18n.t('WARNING'), i18n.t('DND_DELETE_CONNECTED_WARNING'), [
          { text: i18n.t('CANCEL'), style: 'destructive' },
          { text: i18n.t('DELETE_SELECTED'), onPress: () => remove('current') },
          { text: i18n.t('DELETE_ALL'), onPress: () => remove('all') },
        ])
      } else {
        remove('current')
      }
    } else {
      removeUnavailable(ids as Partial<Unavailable>)
      chartRef.current?.remove(event)
    }
  }

  const onDuplicate = (event: EventItem) => {
    const { id, type: technicianType, dtoIds: technicianIds } = chartUtils.extractTechnicianType(event.resourceId)
    const planStart = imDateUtils.toUTC(event.start)
    const planEnd = imDateUtils.toUTC(event.end)
    const type = event.type as DnDType

    if (type === DnDType.ACTIVITY) {
      api.activities.employees
        .post({
          ...event,
          technicianId: id,
          technicianType: technicianType,
          planDateFrom: planStart,
          planDateTo: planEnd,
          plannedByEmployeeId: userSettings?.employee?.id,
        })
        .catch(console.error)
        .finally(eventRefreshGraph)
    } else {
      duplicateUnavailable.current = event
      createUnavailable({ ...technicianIds, fromDate: planStart, toDate: planEnd, srvUnavailableTypeId: event.srvUnavailableTypeId as number })
    }
  }

  const onDuplicateUnlinked = (event: ActivityEmployee) => {
    loadActivity({
      srvActivityId: event.srvActivityId,
      srvActivityTypeId: event.srvActivityTypeId,
      srvActivityYear: event.srvActivityYear,
      taskType,
    })
  }

  const onEventPress = (id: number, typeId: number, activityYear: number, activityState: ActivityState) => {
    const closed = activityUtils.isActivityClosed({ state: activityState })

    if (closed) {
      navigation.navigate('HistoryDetailStack', {
        screen: 'Detail',
        params: {
          srvActivityId: id,
          srvActivityTypeId: typeId,
          srvActivityYear: activityYear,
        },
      })
    } else {
      navigation.navigate('ActivityDetailStack', {
        screen: 'ActivityDetail',
        params: {
          srvActivityId: id,
          srvActivityTypeId: typeId,
          srvActivityYear: activityYear,
          source: 'PlannerStack',
        },
      })
    }
  }

  const loading = !chartReady
  const events = useMemo(() => {
    return dateLabel ? chartUtils.createEvents(data, colorScheme, selectedView, dateLabel.date, showClosedActivities) : []
  }, [data, dateLabel?.date, showClosedActivities])

  return (
    <>
      {loadingNewActivity === 'reloading' && <IM.LoadingSpinnerModal isVisible />}
      <IM.View style={styles.container}>
        <div
          ref={parentRef}
          style={{
            ...styles.chart,
            ...{ width: plannerSideClosed ? '100%' : '70%', backgroundColor: theme.gantt.background },
          }}>
          {chartReady && schedulerData.current && (
            <GanttChart
              ref={chartRef}
              parentRef={parentRef}
              schedulerData={schedulerData.current}
              dndSource={[taskDndSource, absenceDndSource]}
              events={events}
              resources={resources}
              qualifications={qualifications}
              onSelect={onSlotSelect}
              onRefresh={refreshGraph}
              onMove={onDragEnd}
              onMoveStart={onUpdateStart}
              onMoveEnd={onUpdateEnd}
              onRemove={onRemove}
              onDuplicate={onDuplicate}
              onDuplicateUnlinked={evt => onDuplicateUnlinked(evt as unknown as ActivityEmployee)}
              onEventPress={onEventPress}
              onEditPlan={() => null}
            />
          )}
          {loading && <IM.LoadingSpinnerModal isVisible />}
        </div>
        <PlannerSideView
          schedulerData={schedulerData.current}
          newEvent={(_data, slotId, slotName, start, end, type, item) => {
            if (type === 'Activity' && expirationCheck && dayjs(item.expireDate as string).isBefore(dayjs(end))) {
              toast.warn(i18n.t('PLANNER_EXPIRATION_CHECK_MESSAGE'))
            }

            onDragEnd(item, slotId, slotName, start, end, type)
          }}
          taskDndSource={taskDndSource}
          absencesDndSource={absenceDndSource}
          onDatePick={date => chartRef.current?.pick(date)}
          companyId={companyId}
          objectId={objectId}
        />
      </IM.View>
      <ActivityCreationModal controller={createActivityModal} />
    </>
  )
}

export default memo(withDnDContext(GanttChartWrapper))

const styles = StyleSheet.create({
  container: { width: '100%', height: '100%', flexDirection: 'row' },
  chart: { height: '100%', zIndex: 1 },
})
